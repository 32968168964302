import { createRouter, createWebHistory } from 'vue-router';

import Layout from '@/views/pc/layout/index.vue';
import MLayout from '@/views/phone/layout/index.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        meta: { hideToTop: true },
        component: () => import('../views/pc/home/index.vue'),
      },
      {
        path: 'partnership',
        name: 'Partnership',
        meta: { hideToTop: true },
        component: () => import('../views/pc/partnership/index.vue'),
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        meta: { hideToTop: true },
        component: () => import('../views/pc/about/index.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        meta: { hideToTop: true },
        component: () => import('../views/pc/contact/index.vue'),
      },
      {
        path: 'product-solutions/automotive/adas',
        name: 'ADAS',
        component: () => import('../views/pc/adas/index.vue'),
      },
      {
        path: 'product-solutions/automotive/in-cabin',
        name: 'InCabin',
        component: () => import('../views/pc/in-cabin/index.vue'),
      },
      {
        path: 'product-solutions/automotive/apa',
        name: 'APA',
        component: () => import('../views/pc/apa/index.vue'),
      },
      {
        path: 'product-solutions/automotive/commercial-vehicles',
        name: 'CommercialVehicles',
        component: () => import('../views/pc/commercial-vehicles/index.vue'),
      },
      {
        path: 'product-solutions/smartphone/turbo-fusion',
        name: 'TurboFusion',
        component: () => import('../views/pc/turbo-fusion/index.vue'),
      },
      {
        path: 'product-solutions/smartphone/aigc',
        name: 'AigcSolutions',
        component: () => import('../views/pc/aigc-solutions/index.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'privacyPolicy',
        component: () => import('../views/pc/other/privacyPolicy.vue'),
      },
      {
        path: 'terms-of-use',
        name: 'termsOfUse',
        component: () => import('../views/pc/other/termsOfUse.vue'),
      },
      {
        path: '404',
        name: '404',
        component: () => import('../views/pc/404/index.vue'),
      },
      {
        path: ':pathMatch(.*)',
        redirect: '/404',
      },
    ],
  },
  {
    path: '/phone',
    component: MLayout,
    redirect: '/phone',
    children: [
      {
        path: '/phone',
        name: 'MHome',
        component: () => import('../views/phone/home/index.vue'),
      },
      {
        path: 'product-solutions/automotive/adas',
        name: 'MADAS',
        component: () => import('../views/phone/adas/index.vue'),
      },
      {
        path: 'product-solutions/automotive/in-cabin',
        name: 'MInCabin',
        component: () => import('../views/phone/in-cabin/index.vue'),
      },
      {
        path: 'product-solutions/automotive/apa',
        name: 'MAPA',
        component: () => import('../views/phone/apa/index.vue'),
      },
      {
        path: 'product-solutions/automotive/commercial-vehicles',
        name: 'MCommercialVehicles',
        component: () => import('../views/phone/commercial-vehicles/index.vue'),
      },
      {
        path: 'product-solutions/smartphone/turbo-fusion',
        name: 'MTurboFusion',
        component: () => import('../views/phone/turbo-fusion/index.vue'),
      },
      {
        path: 'product-solutions/smartphone/aigc',
        name: 'MAigcSolutions',
        component: () => import('../views/phone/aigc-solutions/index.vue'),
      },
      {
        path: 'partnership',
        name: 'MPartnership',
        component: () => import('../views/phone/partnership/index.vue'),
      },
      {
        path: 'about-us',
        name: 'MAboutUs',
        component: () => import('../views/phone/about/index.vue'),
      },
      {
        path: 'contact',
        name: 'MContact',
        component: () => import('../views/phone/contact/index.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'MprivacyPolicy',
        component: () => import('../views/phone/other/privacyPolicy.vue'),
      },
      {
        path: 'terms-of-use',
        name: 'MtermsOfUse',
        component: () => import('../views/phone/other/termsOfUse.vue'),
      },
      {
        path: '404',
        name: 'M404',
        component: () => import('../views/phone/404/index.vue'),
      },
      {
        path: ':pathMatch(.*)',
        redirect: '/phone/404',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth', // 平滑滚动
      };
    } else {
      return { top: 0 }; // 默认滚动到顶部
    }
  },
});
router.beforeEach((to, from, next) => {
  // const path = window.innerWidth < 800 ? '/phone' : '/pc';
  if (!isNaN(window.orientation) && !to.path.includes('/phone')) {
    next({ path: (to.href = '/' ? '/phone' : to.href) });
  }
  if (isNaN(window.orientation) && to.path.includes('/phone')) {
    next({ path: to.path.replace('/phone', '/') });
  }
  next();
});

export default router;
